<messages>["../Domain"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    scrollable
    mw0>
    <template #activator="{ on: outer }">
      <v-tooltip top>
        <template #activator="{ on: inner }">
          <v-icon v-on="{ ...outer, ...inner }">
            info
          </v-icon>
        </template>
        <span v-t="'view.label.check.click'"/>
      </v-tooltip>
    </template>

    <info-card :data="data" :name="name">
      <v-btn
        v-t="'general.button.close'"
        text
        @click="dialog = false"/>
    </info-card>
  </base-dialog>
</template>

<script>
  import BaseDialog from '@/app/core/components/BaseDialog'
  import InfoCard from './PriceInfoCard'

  export default {
    components: {
      BaseDialog,
      InfoCard
    },

    props: {
      data: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        dialog: false
      }
    }
  }
</script>

<style scoped>
  .inlineCode {
    white-space: pre-line;
    overflow-y: scroll;
  }
  #link {
    text-decoration: none;
  }
  .frame {
    display: block;
    width: 100%;
    height: 50vh;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    box-sizing: border-box;
  }
</style>
