<template>
  <v-icon
    v-if="icon"
    :color="icon.color">
    {{ icon.name }}
  </v-icon>
</template>

<script>
  export default {
    name: 'DomainCheckIcon',

    props: {
      value: {
        type: String,
        required: true
      }
    },

    computed: {
      icon () {
        const ICON_TYPES = {
          Available: {
            name: 'check_circle',
            color: 'green'
          },

          Unavailable: {
            name: 'cancel',
            color: 'red'
          },

          CommunicationError: {
            name: 'help',
            color: 'yellow'
          },

          ErrorFromRegistry: {
            name: 'help',
            color: 'yellow'
          }
        }

        const icon = ICON_TYPES[this.value]

        if (icon) {
          icon.color += ' darken-2'
        }

        return icon
      }
    }
  }
</script>
