<messages>["./Domain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw2>
    <v-col cols="12" lg="5">
      <v-card>
        <form
          novalidate
          @submit.prevent="submit()">
          <v-card-title primary-title>
            <div>
              <div v-t="'view.label.check.domaindata'" class="text-h5"/>

              <div v-t="'view.label.check.domaindataSub'" class="cgwng-subheading"/>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-if="!hasClient"
                cols="12">
                <client-select
                  v-model="clientId"
                  required
                  :v="$v.clientId"/>
              </v-col>
              <template v-if="isRegistryWithPhasesAvailable">
                <v-col cols="12">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-switch
                        v-model="phaseEnabled"
                        class="ml-3"
                        :label="$t ('view.label.check.lp.switch')"
                        v-on="on"
                        @click.native="onTogglePhase"/>
                    </template>
                    <span v-t="'view.label.check.lp.tooltip'"/>
                  </v-tooltip>
                </v-col>
                <v-col
                  v-if="phaseEnabled"
                  cols="12">
                  <registry-select
                    v-model="registryId"
                    spellcheck="false"
                    :with-phases="true"
                    :label="$t ('view.label.check.lp.registry')"
                    :disabled="registrySelectionDisabled"
                    @registryItemsLoaded="onRegistryItems"/>
                  <v-autocomplete
                    v-if="launchPhaseItems.length"
                    v-model="launchPhase"
                    spellcheck="false"
                    :items="launchPhaseItems"
                    :label="$t ('view.label.check.lp.launchPhase')"/>
                </v-col>
              </template>
              <v-col cols="12">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-switch
                      v-model="priceCheck"
                      class="ml-3"
                      :label="$t ('view.label.check.price.price')"
                      v-on="on"
                      @click.native="onTogglePhase"/>
                  </template>
                  <span v-t="'view.label.check.price.tooltip'"/>
                </v-tooltip>
              </v-col>
              <v-slide-y-transition>
                <v-col v-if="priceCheck" cols="12">
                  <v-slider
                    v-model="period"
                    always-dirty
                    thumb-label="always"
                    thumb-size="20"
                    :label="$t ('view.label.check.price.period')"
                    :min="1"
                    :max="10"/>
                </v-col>
              </v-slide-y-transition>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="idnTag"
                  spellcheck="false"
                  :label="$t ('view.label.check.idnTag')"/>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model.trim="domains"
                  name="domains"
                  label="Domains"
                  auto-grow
                  class="required"
                  spellcheck="false"
                  :error-messages="nameErrors ($v.domainList)"
                  @focus="$v.domainList.$reset"
                  @blur="$v.domainList.$touch"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              v-t="'general.button.reset'" text
              @click="reset"/>
            <v-btn
              v-t="'view.label.check.check'" color="primary"
              type="submit"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>

    <v-slide-y-transition>
      <v-col
        v-if="items.length || isLoading"
        cols="12" lg="7">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title primary-title>
                <div v-t="'view.label.check.resultTitle'" class="text-h5"/>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :items-per-page="-1"
                  :loading="isLoading"
                  hide-default-footer
                  :no-data-text="isLoading ? $t ('general.pagination.loading') : $t ('view.label.check.nodata')"
                  class="elevation-1">
                  <template #item="props">
                    <tr>
                      <td>
                        {{ props.item.uname }}
                        <br>
                        <span
                          v-if="props.item.aname"
                          v-text="props.item.aname"/>
                      </td>
                      <td>
                        <domain-check-icon :value="props.item.availability"/>
                      </td>
                      <td v-text="props.item.reason"/>
                      <td v-if="items && items.length > 1">
                        <price-dialog
                          v-if="props.item.payload"
                          :data="props.item.payload"
                          :name="props.item.uname"/>
                      </td>
                      <td v-if="phaseEnabled">
                        <v-icon
                          v-if="!props.item.claimsAcceptanceUrls.length"
                          color="green darken-2">
                          cancel
                        </v-icon>
                        <template v-for="(link, idx) in props.item.claimsAcceptanceUrls">
                          <template v-if="!!link">
                            {{ idx+1 }}.
                          </template>
                          <a
                            v-if="!!link"
                            :key="link"
                            target="_blank"
                            :href="link"
                            v-text="link"/>
                          <span v-else :key="idx" style="color: red">
                            {{ $t ('view.label.check.noLink', {no: idx+1}) }}
                          </span>
                          <br :key="idx+'br'">
                        </template>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="items && items.length == 1 && singleElement && singleElement.payload" cols="12">
            <price-info-card
              :data="singleElement.payload"
              :name="singleElement.uname"/>
          </v-col>
        </v-row>
      </v-col>
    </v-slide-y-transition>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import _pick from 'lodash/pick'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import DomainCheckIcon from './components/DomainCheckIcon'
  import PriceDialog from './components/PriceDialog'
  import PriceInfoCard from './components/PriceInfoCard.vue'

  import {mapActions, mapGetters} from 'vuex'
  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {tld, validateIf} from '@/app/validators'

  const NEW_LINE_REGEX = /\r?\n/

  export default {
    name: 'DomainCheck',

    components: {
      BaseLayout,
      ClientSelect,
      DomainCheckIcon,
      RegistrySelect,
      PriceDialog,
      PriceInfoCard
    },

    data: () => {
      return {
        domains: '',
        domainList: [],
        items: [],
        isLoading: false,
        phaseEnabled: false,
        priceCheck: false,
        period: 1,
        registryId: '',
        registryTypeName: '',
        registrySelectionDisabled: false,
        launchPhase: '',
        launchPhases: [],
        baseNames: [],
        clientId: null,
        isRegistryWithPhasesAvailable: true,
        defaultIdnTag: null,
        idnTag: null
      }
    },

    validations () {
      return {
        clientId: {
          required: requiredIf (() => !this.hasClient)
        },
        domainList: {
          required,
          $each: {
            required,
            tld: validateIf (function () {
              return this.phaseEnabled
            }, tld (this.baseNames))
          }
        }
      }
    },

    computed: {
      ...mapGetters ({
        hasClient: 'auth/hasClient'
      }),

      headers () {
        return [
          {
            text: this.$t ('view.label.check.name'),
            align: 'left',
            value: 'name',
            sortable: false
          },
          {
            text: this.$t ('view.label.check.available'),
            align: 'left',
            value: 'avail',
            sortable: false
          },
          {
            text: this.$t ('view.label.check.reason'),
            align: 'left',
            value: 'reason',
            sortable: false
          },
          ...(this.items && this.items.length > 1
            ? [{
              text: this.$t ('view.label.check.additional'),
              align: 'left',
              value: 'payload',
              sortable: false
            }]
            : []),
          ...(this.phaseEnabled
            ? [{
              text: this.$t ('view.label.check.claims'),
              align: 'left',
              value: 'claims',
              sortable: false
            }]
            : [])
        ]
      },

      launchPhaseItems () {
        const now = new Date ().getTime ()

        return this.launchPhases?.filter?. (p => p.endDate === null || p.endDate > now).map?. (it => ({
          value: _pick (it, ['name', 'subname', 'startDate', 'defaultPhase']),
          text: it.subname ? it.name + ' - ' + it.subname : it.name
        })) || []
      },

      singleElement () {
        return this.items && this.items.length === 1 ? this.items[0] : undefined
      }
    },

    watch: {
      domains (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.domainList = typeof newValue === 'string'
            ? newValue.split (NEW_LINE_REGEX)
            : []
        }
      },

      registryId (newValue) {
        this.loadMetaData (newValue)
      },

      launchPhaseItems (newValue) {
        const now = new Date ().getTime ()

        this.launchPhase = newValue?.find?. (p => p.value.defaultPhase &&
          (p.value.startDate === null || p.value.startDate <= now))?.value
      },

      defaultIdnTag (newValue) {
        this.idnTag = newValue
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      async populateRegistryTypeName (registryType) {
        this.registryTypeName = await this.getRegistryTypeName ({id: registryType})
      },

      reset () {
        this.domains = ''
        this.domainList = []
        this.items = []
        this.isLoading = false
        this.phaseEnabled = false
        this.registryId = ''
        this.registryTypeName = ''
        this.registrySelectionDisabled = false
        this.launchPhase = ''
        this.launchPhases = []
        this.baseNames = []
        this.clientId = null
        this.isRegistryWithPhasesAvailable = true
        this.defaultIdnTag = null
        this.idnTag = null
      },

      submit () {
        if (!this.$v.$invalid) {
          this.isLoading = true
          this.items = []

          const params = {
            names: this.domainList,
            launchPhase: this.phaseEnabled ? _pick (this.launchPhase, ['name', 'subname']) : null,
            idnTag: this.idnTag
          }

          if (!this.hasClient) {
            params.clientId = this.clientId
          }

          if (this.priceCheck) {
            params.checkPrice = true
            params.period = this.period
          }

          this.fetchData ({
            op: 'domain/check',
            params,
            cb: data => {
              this.items = data.domainData.filter (i => i)
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
          this.$v.domainList.$reset ()
        } else {
          this.$v.$touch ()
        }
      },

      onTogglePhase () {
        if (this.phaseEnabled) this.items = []
      },

      /**
       * Pre-select registry if only one registry is available for the current
       * user.
       */
      onRegistryItems (registryItems) {
        this.isRegistryWithPhasesAvailable = !!registryItems?.length

        if (registryItems.length === 1) {
          this.registryId = registryItems[0].value
          this.registrySelectionDisabled = true
        }
      },

      /**
       * Determine the domain name related error messages of the given
       * validations object.
       *
       * @param {Object} validations      the validations object to evaluate
       * @return {Array}                  the error messages
       */
      nameErrors (validations) {
        let errors = []

        if (validations) {
          const REQUIRED = this.$t ('create.required.domainName')
          const WRONG_BASE_NAME = this.$t ('create.invalid.baseName')

          if (validations.$dirty) {
            if (validations.required !== undefined && !validations.required) {
              errors.push (REQUIRED)
            }

            for (const i in validations.$model) {
              if (validations.$each[i].required !== undefined &&
                !validations.$each[i].required &&
                !errors.includes (REQUIRED)) {
                errors.push (REQUIRED)
              }

              if (!validations.$each[i].tld &&
                !errors.includes (WRONG_BASE_NAME)) {
                errors.push (WRONG_BASE_NAME)
              }
            }
          }

          if (errors.length > 1) {
            errors = [
              `${this.$t ('general.multipleErrors')}: ${errors.join ('. ')}.`
            ]
          }
        }

        return errors
      },

      /**
       * Load the registry-specific meta data for the registry with the given
       * ID.
       *
       * @param {String} registryId     the registry ID
       */
      loadMetaData (registryId) {
        console.log ('loading meta data')
        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: registryId
          },
          cb: data => {
            const rmd = data.registryMetaData
            this.launchPhases = rmd.launchPhases
            this.baseNames = rmd.baseNames
            this.defaultIdnTag = rmd.defaultIdnTag
          }
        })
      }
    }

  }
</script>
