<messages>["../Domain"]</messages>

<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="text-h5">
          {{ $t ('view.label.check.title', {name: name}) }}
        </div>
        <div
          v-t="'view.label.check.subtitle'"
          class="cgwng-subheading"/>
      </div>
    </v-card-title>

    <v-card-text>
      <v-card
        class="pa-2"
        flat
        color="grey lighten-2">
        <div class="monospaced">
          <pre>{{ data }}</pre>
        </div>
      </v-card>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <slot/>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {

    props: {
      data: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    }
  }
</script>
